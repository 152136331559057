import React, { useEffect, useMemo, useState, useRef } from 'react';
import querystring from 'querystring';
import { withStyles } from '@material-ui/core/styles';
import * as quotationApi from '../api/quotation-api';
import * as deliveryApi from '../api/delivery-api';
import * as dictionaryApi from '../api/dictionary-api';
import { parseQuotationExcel } from '../utils/excel';
import { saveExcel } from '../utils/excel';
import { getCookie } from '../utils/cookies';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HelpTooltipContent from '../components/HelpTooltipContent';

import {
  Button,
  Tabs,
  Tab,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  Page,
  SearchInput,
  SearchTable,
  ActionsGroup,
  Select,
  SelectFine,
} from '../components';

const deliveryTermLimit = [
  { title: '1', value: 1 },
  { title: '4', value: 4 },
  { title: '7', value: 7 },
  { title: '14', value: 14 },
  { title: '30', value: 30 },
  { title: '60', value: 60 },
  { title: '180', value: 180 },
];

const SearchPage = ({ classes, history, location, ...rest }) => {
  const { t } = useTranslation();

  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [search, setSearch] = useState('8532126020');
  const [data, setData] = useState([]);

  const [deliveryTerm, setDeliveryTerm] = useState(4);
  const [tab, setTab] = useState(0);
  const [isFileUploading, setFileUploading] = useState(false);
  const [only, setOnly] = useState(false);
  const [deliveryTariffList, setDeliveryTariffList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const lastQuotationId = useRef(0);
  const initialized = useRef(false);

  const [deliveryTariff, setDeliveryTariff] = useState([]);
  const [currency, setCurrency] = useState([]);

  const openFileDialog = useRef();

  const columns = useMemo(
    () => [
      { name: 'PartNumber', width: 10 },
      { name: 'Brand', width: 10 },
      { name: 'Quantity' },
      { name: 'Price' },
      { name: 'Reference' },
      { name: 'Booking' },
      { name: 'Only' },
      { name: 'Days' },
      { name: 'Description' },
      { name: 'Substituted' },
      { name: 'WeightKG' },
      { name: 'VolumeKG' },
    ],
    []
  );

  const outputData = useMemo(
    () =>
      data.map((value) => [
        value.partNumber,
        value.brand,
        value.quantity &&
        (!value.available || value.quantity <= value.available)
          ? value.quantity
          : value.available,
        value.price,
        value.yourReference,
        value.booking,
        value.only ? 'YES' : '',
        value.days,
        value.description,
        value.inpPartNumber !== value.partNumber ? value.inpPartNumber : '',
        value.weightKg,
        value.volumeKg,
      ]),
    [data]
  );

  /**
   * Searching for part number
   */
  async function getDeliveryTariffList() {
    const result = await deliveryApi.deliveries(true, false);

    setDeliveryTariffList(
      result.map((row) => ({
        title: row['text'],
        value: row['id'],
      }))
    );

    if (result.length !== 0) setDeliveryTariff(result[0].id);
  }

  async function getCurrencyList() {
    const result = await dictionaryApi.currencies();

    setCurrencyList(
      result.map((row) => ({
        title: row['code'],
        value: row['code'],
      }))
    );

    if (result.length !== 0) setCurrency(result[0].code);
  }

  useEffect(() => {
    lastQuotationId.current = 0;
    fetchDataBySearch();
  }, [location.search]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDeliveryTariffList().then();
      getCurrencyList().then();
    }
  }, []);

  useEffect(() => {
    fetchOnly().then();
  }, [currency]);

  useEffect(() => {
    lastQuotationId.current = 0;
    setData([]);
  }, [deliveryTariff, deliveryTerm, tab]);

  useEffect(() => {
    fetchOnly().then();
  }, [localStorage.getItem('selectedLanguage')]);

  async function fetchData(query, maxDays, onlyBest) {
    setData([]);

    //setShowWarn(false);

    const result = await quotationApi.process(
      query,
      maxDays,
      onlyBest,
      currency
    );

    setData(result);

    if (result && result.length) {
      lastQuotationId.current = result[0].quotationId;
    }

    /*
    const warnData = result.filter(value => value.err !== 0);
    setShowWarn(warnData.length>0);
    */
  }

  async function fetchOnly() {
    if (lastQuotationId.current) {
      setData([]);
      const result = await quotationApi.select(
        lastQuotationId.current,
        currency
      );
      setData(result);
    }
  }

  function handleChangeCurrency(value) {
    setCurrency(value);
  }

  function fetchDataBySearch() {
    const params = querystring.parse(location.search.slice(1));
    if (params.part !== undefined) {
      setSearch(params.part);
      fetchData(
        [{ partNumber: params.part, delivery: deliveryTariff, only: only }],
        0
      ).then();
    }
  }

  function handleSearchInputChange(value) {
    setSearch(value);
  }

  function handleSearchInputSearch() {
    const params = querystring.parse(location.search.slice(1));
    if (params.part === search) fetchDataBySearch();
    else
      history.push({
        pathname: '/search',
        search: `?part=${search}`,
      });
  }

  function handleDataChange(data) {
    setData(data);
  }

  async function handleTableSelect(id) {
    const newData = data.map((item) => {
      return item.id !== id ? item : { ...item, confirmed: !item.confirmed };
    });
    setData(newData);
  }

  async function handleTableSelectAll(confirmed) {
    const newData = data.map((item) => {
      return { ...item, confirmed };
    });
    setData(newData);
  }

  async function handleToBasket() {
    if (isLoggedIn)
      try {
        const { quotationId } = data[0];
        if (quotationId && quotationId > 0) {
          await quotationApi.confirmAndToBasket(
            quotationId,
            data.filter((value) => value.quantity > 0)
          );
          setData([]);
          lastQuotationId.current = 0;
        }
      } catch (e) {
        // empty
      }
  }

  async function handleToExcel() {
    await saveExcel('Quotation', columns, outputData);
  }

  function handleTabChange(event, value) {
    setTab(value);
  }

  function handleFileOpen() {
    openFileDialog.current.click();
  }

  function handleDeliveryTermChange(value) {
    setDeliveryTerm(value);
  }

  async function handleDeliveryTariffChange(value) {
    setDeliveryTariff(value);

    if (!lastQuotationId.current) return;

    const result = await quotationApi.refresh(
      lastQuotationId.current,
      value,
      0,
      false,
      currency
    );
    lastQuotationId.current = result[0].quotationId;
    setData(result);
  }

  async function handleQuotationExcel(event) {
    setFileUploading(true);
    let excelData = [];
    try {
      const file = event.target.files[0];
      event.target.value = '';
      if (!file) return;
      excelData = await parseQuotationExcel(file);
    } catch (err) {
      alert(err.message);
    }

    if (!excelData.length) {
      setFileUploading(false);
      return;
    }

    try {
      await fetchData(
        excelData.map((row) => ({
          partNumber: row['PartNumber'],
          brand: row['Brand'],
          quantity: row['Quantity'],
          price: row['Price'],
          only: row['Only'],
          yourReference: row['Reference'],
          booking: row['Booking'],
          delivery: deliveryTariff,
        })),
        deliveryTerm,
        true
      );

      setFileUploading(false);
    } catch (err) {
      alert(t('error_quotation'));
      setFileUploading(false);
    }
  }

  return (
    <Page>
      <Tabs
        className={classes.tabs}
        value={tab}
        centered
        onChange={handleTabChange}
      >
        <Tab
          label={t('by_part_number')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label={t('by_excel_file')}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      </Tabs>

      {tab === 0 ? (
        <div className={classes.searchContainer}>
          <SearchInput
            value={search}
            onChange={handleSearchInputChange}
            onSearch={handleSearchInputSearch}
            only={only}
            setOnly={setOnly}
          />
        </div>
      ) : (
        <div className={classes.searchContainerByFile}>
          <Button
            disabled={isFileUploading}
            className={classes.leftButton}
            variant="contained"
            onClick={handleFileOpen}
          >
            {t('upload')}
          </Button>
          <input
            ref={openFileDialog}
            type="file"
            hidden
            style={{ height: 0, width: 0 }}
            accept=".xlsx"
            onChange={handleQuotationExcel}
          />

          <div className={classes.deliveryTermContainer}>
            <Typography className={classes.deliveryTermLabel} variant="body1">
              {t('max_days')}:
            </Typography>
            <Select
              items={deliveryTermLimit}
              value={deliveryTerm}
              onChange={handleDeliveryTermChange}
              className="max-days"
            />
          </div>

          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              gap: '8px',
            }}
          >
            <Tooltip
              interactive
              title={<HelpTooltipContent />}
              placement="bottom"
            >
              <HelpOutlineIcon />
            </Tooltip>
            <Button
              className={classes.rightButton}
              variant="contained"
              component="a"
              href="/quote_template.xlsx"
              download
            >
              {t('template')}
            </Button>
          </span>
        </div>
      )}

      <ActionsGroup className={classes.actionsGroup}>
        <Button
          disabled={!data.length || isFileUploading || !isLoggedIn}
          className={classes.basketButton}
          variant="contained"
          color="primary"
          onClick={handleToBasket}
        >
          {t('to_cart')}
        </Button>

        <SelectFine
          items={deliveryTariffList}
          value={deliveryTariff}
          /*label="Dispatch"*/
          onChange={handleDeliveryTariffChange}
        />

        <SelectFine
          items={currencyList}
          value={currency}
          /*label='Currency'*/
          onChange={handleChangeCurrency}
        />

        <Button
          disabled={!data.length || isFileUploading}
          className={classes.excelButton}
          variant="contained"
          color="default"
          onClick={handleToExcel}
        >
          {t('export')}
        </Button>
      </ActionsGroup>

      <div className={classes.tableContainer}>
        <SearchTable
          data={data}
          showReference={tab === 1}
          onDataChange={handleDataChange}
          onSelect={handleTableSelect}
          onSelectAll={handleTableSelectAll}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </Page>
  );
};

/*
<SearchFilter
  filter={filter}
  disabled={!data.length}
  onFilterChange={handleFilterChange}
/>
*/

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  const searchContainer = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.tabSelectedBackground,
    height: '72px',
    minHeight: '72px',
    marginBottom: theme.spacing.unit,
    padding: `0 ${theme.spacing.unit}px`,
  };
  return {
    tabs: {
      minHeight: 40,
    },
    tabRoot: {
      backgroundColor: colors.tabBackground,
      opacity: 1,
      minHeight: 40,
    },
    tabSelected: {
      backgroundColor: colors.tabSelectedBackground,
      color: '#fff',
    },
    searchContainer: {
      ...searchContainer,
      justifyContent: 'center',
    },
    searchContainerByFile: {
      ...searchContainer,
      justifyContent: 'space-between',
    },
    deliveryTermContainer: {
      flex: '1 0 0px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing.unit,
    },
    deliveryTermLabel: {
      color: colors.deliveryTermLabel,
      marginRight: theme.spacing.unit,
    },
    actionsGroup: {
      justifyContent: 'space-between',
    },
    basketButton: {},
    excelButton: {},
    tableContainer: theme.tableContainer,
    nextContainer: {
      marginLeft: theme.spacing.unit,
    },
    downContainer: {
      marginTop: theme.spacing.unit,
    },
    leftButton: {
      marginLeft: theme.spacing.unit,
    },
    rightButton: {},
    whiteControl: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&:hover .MuiOutlinedInput-input': {
        color: 'red',
      },
    },
  };
};

export default withStyles(styles)(SearchPage);
