import React from 'react';
import classNames from 'classnames';
import {
  withStyles,
  OutlinedInput,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

const SearchInput = ({
  classes,
  className,
  value,
  onChange,
  onSearch,
  only,
  setOnly,
}) => {
  const { t } = useTranslation();

  function handleChange(event) {
    const { value } = event.target;
    onChange && onChange(value);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') handleSearchClick();
  }

  function handleSearchClick() {
    onSearch && onSearch();
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <OutlinedInput
        className={classNames(classes.input, className)}
        autoComplete="on"
        value={value}
        placeholder={t('search_input_placeholder')}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleSearchClick}>
              <SearchIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        }
      />
      <Tooltip title={t('only_description_tooltip')}>
        <FormControlLabel
          control={
            <Checkbox
              disableRipple
              checked={only}
              onChange={() => setOnly(!only)}
            />
          }
          label={t('only_description_label')}
        />
      </Tooltip>
    </div>
  );
};

const styles = (theme) => {
  const { backgroundColor } = theme.palette.custom.searchInput;
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2px 4px',

      backgroundColor,
    },
    input: {
      width: '400px',
      height: '36px',
      borderRadius: theme.shape.borderRadius,
      backgroundColor,
    },
  };
};

export default withStyles(styles)(SearchInput);
