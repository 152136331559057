import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';

if (!localStorage.getItem('selectedLanguage')) {
  localStorage.setItem('selectedLanguage', 'en');
}

const resources = {
  en: {
    translation: {
      //NAVIGATION
      home: 'Home',
      about: 'About Us',
      product_list: 'Product List',
      gallery: 'Gallery',
      contact: 'Contact',

      search: 'Search',
      cart: 'Cart',
      orders: 'Orders',
      dispatch: 'Dispatch',
      balance: 'Balance',
      offers: 'Offers',

      sign_up: 'Sign Up',
      log_in: 'Log In',
      log_out: 'Log Out',
      profile: 'Profile',
      //HOME PAGE
      shop_now: 'Shop Now',
      news: 'News & articles',
      oem_brands: 'Oem Brands',
      aftermarket_brands: 'Aftermarket Brands',
      view_all: 'View All',

      //footer
      my_account: 'My Account',
      search_product: 'Search Products',
      shopping_cart: 'Shopping Cart',
      resources: 'Resources',
      faq: 'FAQ',
      //about page
      companyProfileHeading: 'Company Profile',
      para1:
        ' is one of the key participants in Dubai Auto Spare Parts business that has entirely altered the universal marketing and formed a hub for re-export business.',
      para2:
        " was established in 1999. It fully understands the global needs of vehicle's spare parts through the knowledge of the changes in overseas market trend. The company extended its business with partners in Malaysia, Ukraine (Odessa, Kyiv), Bulgaria, Poland, Germany, and some others Europe countries, in the Middle east and Gulf.",
      para3:
        ' team shares a common business idea and continually enhances our products and services assortment. We maintain an active re-export planning strategy, which ensures global emerging opportunities. Our Company caters the needs of all Japanese,Korean,Europe cars as well as car spare parts. Our extensive portfolio of products covers a variable range and quality, including original and replacement spare parts for',
      para4:
        "aims to be an important organizer in the Auto Spare Parts re-export trading, with gradual improvement, prompt service and reliability. The company reputed worldwide organizations and comprehensive support of Trading Giants. It ensures the best service to meet all your auto spare parts requirements. Our staff and management allocate a general idea that Nippon's Suppliers and Customers are not only mere supporters, but they are the base of our business.",
      para5:
        ' for their valuable patronage during these years and offer our best service at all times.',
      para6: 'We thank & salute all of our customers and suppliers',

      whyChooseUsHeading: 'Why choose us',
      qualityPolicyHeading: 'Quality policy',
      qualityPolicyText:
        'It is a fundamental objective of NIPPON MOTORS FZCO to provide high quality products and customer service throughout the world. Therefore the company shall, at all times strive to achieve a level of customer satisfaction and quality image that will portray us as a leader in our defined markets. It is essential that the commitment to a quality product and quality service extend throughout the whole organization in order to achieve our goals of a satisfied customer. Thus it becomes the philosophy of the company that the customer in terms of product specifications cost, and delivery requirement defines this quality.',
      ourMissionHeading: 'Our Mission',
      ourMissionText:
        'To be a market leader in the region with global interestsrenowned for its high quality in providing products and services, in a cost-effective and friendly manner that exceeds customer expectation through the efforts of everyone.',
      ourSpecialtyHeading: 'Our Specialty',
      ourSpecialtyText1:
        'Our specialty is to provide our customers with a total range of Engine and suspension spare parts for Japanese and Korean diesel and petrol cars. This allows us to combine Genuine parts and aftermarket high quality level under the same roof.',
      ourSpecialtyText2:
        'We believe this combination gives us an advantage over our competitors. We offer this package of services, for which our customers benefit in knowing that all there requirement are being coordinated through one dealer',

      item1: 'Timing Belt, Timing Kit, Timing Chain',
      item2: 'Rib Belt, Fan Belt',
      item3: 'Piston, Liners',
      item4: 'Piston ring',
      item5: 'Valve seal, Oil seal',
      item6: 'Suspension parts',
      item7: 'C.V. joint boot',
      item8: 'Valve cover gasket, Oil Pan',
      item9: 'Shock absorber boot',
      item10: 'Brake pad, brake shoe',
      item11: 'Air filters, Oil Filters, Fuel Filters',
      item12: 'Main Bearing, Con Bearing',
      item13: 'Engine Valve, Valve Guide',
      item14: 'Engine Bearing, Wheel Bearing',

      //contact page
      get_in_touch: 'Get in touch',
      contact_us: 'Contact Us',
      email_address: 'Email Address',
      office_location: 'Office Location',
      enquire_now: 'Enquire Now',
      firstName: 'First Name*',
      lastName: 'Last Name*',
      phoneNumber: 'Phone Number*',
      message: 'Message*',
      message_placeholder: 'Write Your Message',
      submit_form: 'Submit',
      error: 'Error',
      success: 'Sent',

      // User data fields
      user_name: 'NAME',
      user_country: 'COUNTRY',
      user_zip: 'POST CODE',
      user_state: 'STATE/PROVINCE',
      user_city: 'CITY',
      user_street: 'STREET',
      user_building: 'BUILDING',
      user_flat: 'FLAT/OFFICE',
      user_phone: 'PHONE',
      user_email: 'EMAIL',
      user_tax: 'TAX NUMBER',
      user_currency: 'CURRENCY',
      user_contact_person: 'CONTACT PERSON',
      user_contact_email: 'EMAIL',
      user_contact_phone: 'CONTACT PHONE',
      save: 'SAVE',

      //verify email
      verify_email_processing: 'Verifying...',
      verify_email_heading: 'Email verification',
      verify_email_text: 'We have sent you an email to',
      verify_email_text2:
        'Please, check your inbox and click the link in the message',
      close_text: 'Close',

      // Search page
      by_part_number: 'By Part Number',
      by_excel_file: 'By Excel File',
      to_cart: 'To Cart',
      export: 'Export',
      upload: 'Upload',
      max_days: 'Max days',
      template: 'Template',
      demo_prices_log_in:
        'You see DEMO prices. To get actual ones, kindly login',
      demo_prices_complete_registration:
        'You see DEMO prices. To get actual ones, kindly deposit USD500 to complete registration',
      for_order: 'To Order',
      error_quotation: 'Cannot process quotation due to error',
      quotation_differs:
        'Some items you can purchase with new lead times, prices or quantities',
      where_to_dispatch: 'Delivery',
      only_description_tooltip: 'Search without analogues and substitutes',
      only_description_basket: 'Without analogues and substitutes',
      only_description_label: 'Only',

      // Balance page
      cannot_export_invoice: 'Cannot export invoice due to the error',
      start_date: 'Start Date',
      end_date: 'End Date',
      show: 'Show',
      topup_online: 'TopUp Online',
      bank_transfer: 'Bank Transfer',
      document: 'document',
      debit: 'Debit',
      credit: 'Credit',

      // Basket page
      cannot_select_item: 'Cannot select item(s) due to the error',
      cannot_change_quantity: 'Cannot change quantity due to the error',
      cannot_apply_changes: 'Cannot apply changes due to the error',
      cannot_order_selected: 'Cannot order selected due to the error',
      cannot_delete_selected: 'Cannot delete selected due to the error',
      cannot_change_ref: 'Cannot change reference due to the error',
      order: 'Order',
      remove: 'Remove',
      order_accepted:
        'Your order № {{orderId}} in amount of {{currency}}{{amount}} is accepted.',
      order_not_accepted:
        'Your order not accepted due to the error (code = {{orderId}})',
      activate_account: 'Please contact us to activate your account',
      insufficient_funds: 'Insufficient funds, please check your balance',
      no_items: 'No items for order',
      incorrect_items: 'There are incorrect items in the cart',
      basket_log_in: 'To place orders, kindly login',
      basket_complete_registration:
        'To place orders, kindly deposit USD500 to complete registration',

      // Orders page
      part_number: 'Part number',
      order_number: 'Order number',
      reference: 'Reference',
      all: 'all',

      // Password reset page
      submit: 'Submit',
      new_password: 'NEW PASSWORD',

      // Shipments page
      cannot_update_shipment_date:
        'Cannot update shipment date due to the error',

      // Balance topup dialog
      amount_with_currency: 'AMOUNT ({{currency}})',
      charge: 'CHARGE: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'TOP UP',

      // Balance total table
      balance_with_currency: 'Balance ({{currency}})',
      in_orders: 'In Orders',
      in_cart: 'In Cart',
      pay_for_cart: 'Pay for Cart',
      ready_to_ship: 'Ready to Ship',
      pay_to_ship: 'Pay to Ship',

      // Basket table
      brand: 'Brand',
      price_with_currency: 'Price ({{currency}})',
      quantity: 'Quantity',
      total_with_currency: 'Total ({{currency}})',
      weight_kg: 'Weight (kg)',
      booking: 'Booking',
      delivery: 'Delivery',
      description: 'Description',
      duplication: 'Duplication',

      // Basket total table
      to_order: 'To Order',
      available_for_order: 'Available for Order',
      pay_for_order: 'Pay for Order',
      items: 'Items',

      // Captcha
      captcha_field_placeholder: 'ENTER TEXT FROM AN IMAGE ABOVE',

      //Catalog page
      offers_log_in: 'To download offers, kindly login',
      offers_complete_registration:
        'To download offers, kindly deposit USD500 to complete registration',

      // Catalog table
      offer: 'Offer',
      download: 'Download',
      lines: 'Lines',
      updated: 'Updated',

      //Email verified page
      email_verified: 'Your email has been verified',

      // Log In dialog
      email: 'email',
      password: 'password',
      forgot_password: 'Forgot password?',
      reset_link_sent: 'Reset link is sent to your email',

      // Orders table
      date: 'Date',
      order_price: 'Order Price',
      sale_price: 'Sale Price',
      ordered: 'Ordered',
      purchased: 'Purchased',
      shipped: 'Shipped',
      refused: 'Refused',
      state_date: 'State Date',

      // Search input
      search_input_placeholder: 'Search...',

      // Search table
      substituted: 'Substituted',
      days: 'Days',
      available: 'Available',
      volume_kg: 'Volume (kg)',
      comment: 'Comment',
      n_a: 'N/A',
      pack: 'Pack',

      // Shipment date dialog
      enabled: 'Enabled',

      // Shipment box table
      row_id: 'Row ID',

      // Shipment table
      place: 'place',
      length_m: 'Length (m)',
      height_m: 'Height (m)',
      width_m: 'Width (m)',
      value_with_currency: 'Value ({{currency}})',

      // Shipment total table
      places: 'Places',
      volume_m3: 'Volume (㎥)',
      schedule: 'Schedule',
      choose_date: 'Choose date',

      // Sign up dialog
      company_name: 'company name',
      contact_phone: 'contact phone',
      confirm_password: 'confirm password',
      error_password_not_match: 'Passwords do not match',

      //Manuals page
      tab_register: 'register',
      tab_quotations: 'make quotations',
      tab_offers: 'download offers',
      tab_api: 'use API',

      //TopUp Dialog
      error_amount: 'Incorrect amount',
      error_something: 'Something went wrong, contact us',

      quote_template_intro: 'The process is quite simple:',
      quote_template_step1:
        'Fill up and save an Excel file in a predefined format with the part numbers you are looking for',
      quote_template_step2:
        'You can download the template with the TEMPLATE button or use existing one',
      quote_template_step2_1:
        'It is mandatory to match the column names to the template',
      quote_template_step2_2: 'The template contains the following columns:',
      quote_template_PARTNUMBER: 'Part number (Required)',
      quote_template_BRAND:
        "Optional, but required if you don't want alternative brands",
      quote_template_QUANTITY: 'Desired quantity (Optional)',
      quote_template_PRICE:
        'Set a target price (Optional). If unavailable, the lowest available price will be shown with a notification',
      quote_template_REFERENCE:
        'Your personal reference, will be printed on a sticker',
      quote_template_BOOKING:
        'To select the source of price and delivery (NIP1, AMZB, VF07, ...)',
      quote_template_ONLY: 'Write "YES" if you want only this specific brand',
      quote_template_MAX_DAYS:
        'Choose your lead time limit in the MAX DAYS box',
      quote_template_step4:
        'Click UPLOAD button and choose your prepared Excel file',
      quote_template_step5:
        'Fill the QUANTITY field for positions you like and click TO CART to proceed with the order, or click EXPORT to download the quotation results',
    },
  },
  ru: {
    translation: {
      //NAVIGATION
      home: 'Главная',
      about: 'О нас',
      product_list: 'Список продукции',
      gallery: 'Галерея',
      contact: 'Контакты',

      search: 'Поиск',
      cart: 'Корзина',
      orders: 'Заказы',
      dispatch: 'Отправка',
      balance: 'Баланс',
      offers: 'Предложения',

      sign_up: 'Регистрация',
      log_in: 'Вход',
      log_out: 'Выйти',
      profile: 'Профиль',
      //HOME PAGE
      shop_now: 'Смотреть каталог',
      news: 'Новости и статьи',
      oem_brands: 'Оригинальные бренды',
      aftermarket_brands: 'Бренды вторичного рынка',
      view_all: 'Все новости',

      //footer
      my_account: 'Профиль',
      search_product: 'Поиск продукции',
      shopping_cart: 'Корзина',
      resources: 'Ресурсы',
      faq: 'FAQ',

      //about page
      companyProfileHeading: 'Профиль Компании',
      para1:
        ' является одним из ключевых участников бизнеса по продаже автозапчастей в Дубае, который полностью изменил универсальный маркетинг и сформировал хаб для реэкспортного бизнеса.',
      para2:
        ' была основана в 1999 году. Компания полностью понимает глобальные потребности в запчастях для автомобилей на основе знаний об изменениях в зарубежных рыночных трендах. Компания расширила свой бизнес с партнерами в Малайзии, Украине (Одесса, Киев), Болгарии, Польше, Германии и в некоторых других европейских странах, на Ближнем Востоке и в Заливе.',
      para3:
        ' разделяет общую бизнес-идею и постоянно улучшает ассортимент продуктов и услуг. Мы поддерживаем активную стратегию планирования реэкспорта, которая обеспечивает глобальные возникающие возможности. Наша компания удовлетворяет потребности всех японских, корейских, европейских автомобилей, а также автозапчастей. Наш обширный портфель продуктов охватывает переменный диапазон и качество, включая оригинальные и запчасти-дубликаты для',
      para4:
        ' стремится быть важным организатором в торговле реэкспортом автозапчастей, с постепенным улучшением, оперативным обслуживанием и надежностью. Компания заслужила мировую репутацию организаций и всеобъемлющую поддержку торговых гигантов. Это обеспечивает лучшее обслуживание для удовлетворения всех ваших потребностей в автозапчастях. Наш персонал и руководство распределяют общую идею, что поставщики и клиенты Nippon не просто поддерживают нас, но являются основой нашего бизнеса.',
      para5:
        ' за их ценную поддержку на протяжении этих лет и всегда предлагаем наши лучшие услуги.',
      para6: 'Мы благодарим и приветствуем всех наших клиентов и поставщиков',
      whyChooseUsHeading: 'Почему выбирают нас',
      qualityPolicyHeading: 'Политика качества',
      qualityPolicyText:
        'Фундаментальной целью NIPPON MOTORS FZCO является предоставление высококачественных продуктов и обслуживания клиентов по всему миру. Поэтому компания в любое время стремится добиться уровня удовлетворенности клиентов и качественного образа, который отразит нас как лидера на наших рынках. Для достижения наших целей - удовлетворения потребностей клиентов - необходимо, чтобы приверженность качественному продукту и качественному обслуживанию распространялась на всю организацию. Таким образом, философия компании заключается в том, что заказчик в части технических характеристик продукта, стоимости и требований к поставке определяет это качество.',
      ourMissionHeading: 'Наша Миссия',
      ourMissionText:
        'Стать рыночным лидером в регионе с глобальными интересами, известным своим высоким качеством в предоставлении продуктов и услуг, экономичным и дружелюбным способом, превышающим ожидания клиента, благодаря усилиям каждого.',
      ourSpecialtyHeading: 'Наша Специализация',
      ourSpecialtyText1:
        'Нашей специализацией является предоставление нашим клиентам полного ассортимента запасных частей для двигателя и подвески для японских и корейских дизельных и бензиновых автомобилей. Это позволяет нам сочетать оригинальные запчасти и запчасти послепродажного обслуживания высокого качества под одной крышей.',
      ourSpecialtyText2:
        'Мы считаем, что это сочетание дает нам преимущество перед нашими конкурентами. Мы предлагаем этот пакет услуг, от которого наши клиенты получают выгоду, зная, что все их требования координируются через одного дилера.',
      item1: 'Ремни ГРМ, Комплект ГРМ, Цепи ГРМ',
      item2: 'Ребристые ремени, Вентиляторные ремень',
      item3: 'Поршени, Гильзы',
      item4: 'Кольца поршневые',
      item5: 'Сальники клапана, Сальники маслянные',
      item6: 'Детали подвески',
      item7: 'Анкерный манжет ШРУСа',
      item8: 'Прокладки крышек клапанов, Поддоны',
      item9: 'Защитный манжет амортизаторов',
      item10: 'Тормозные колодки, Тормозные башмаки',
      item11: 'Воздушные фильтры, Масляные фильтры, Топливные фильтры',
      item12: 'Подшипники коленвала, Шатунные подшипники',
      item13: 'Клапан двигателя, Направляющая клапана',
      item14: 'Подшипники двигателя, Подшипники колеса',

      //contact page
      get_in_touch: 'Связаться с нами',
      contact_us: 'Контакты',
      email_address: 'Адрес Email',
      office_location: 'Расположение офиса',
      enquire_now: 'Задать вопрос',
      firstName: 'Имя*',
      lastName: 'Фамилия*',
      phoneNumber: 'Телефон*',
      message: 'Cообщение*',
      message_placeholder: 'Напишите своё сообщение',
      submit_form: 'Отправить',
      error: 'Ошибка',
      success: 'Отправлено',
      //user dialog
      user_name: 'НАИМЕНОВАНИЕ',
      user_country: 'СТРАНА',
      user_zip: 'ИНДЕКС',
      user_state: 'ШТАТ/ПРОВИНЦИЯ',
      user_city: 'ГОРОД',
      user_street: 'УЛИЦА',
      user_building: 'ДОМ',
      user_flat: 'КВАРТИРА/ОФИС',
      user_phone: 'ТЕЛЕФОН',
      user_email: 'ЭЛЕКТРОННАЯ ПОЧТА',
      user_tax: 'НАЛОГОВЫЙ НОМЕР',
      user_currency: 'ВАЛЮТА',
      user_contact_person: 'КОНТАКТНОЕ ЛИЦО',
      user_contact_email: 'EMAIL',
      user_contact_phone: 'КОНТАКТНЫй ТЕЛЕФОН',
      save: 'СОХРАНИТЬ',

      //verify email
      verify_email_processing: 'Обработка...',
      verify_email_heading: 'Подтверждение адреса email',
      verify_email_text: 'Мы отправили вам письмо на',
      verify_email_text2:
        'Пожалуйста, проверьте свой почтовый ящик и перейдите по ссылке в сообщении',
      close_text: 'Закрыть',
      // Search page
      by_part_number: 'Поиск по номеру',
      by_excel_file: 'Проценка файлом',
      to_cart: 'В корзину',
      export: 'Экспорт',
      upload: 'Загрузить',
      max_days: 'Не позднее',
      template: 'Шаблон',
      demo_prices_log_in: 'Вы видите ДЕМО цены. Авторизуйтесь',
      demo_prices_complete_registration:
        'Вы видите ДЕМО цены. Внесите депозит USD500, чтобы завершить регистрацию',
      for_order: 'ЗАКАЗ',
      error_quotation: 'Ошибка выполнения котировки',
      quotation_differs:
        'По части позиций отличается цена, наличие или срок поставки',
      where_to_dispatch: 'Доставка',
      only_description_tooltip: 'Поиск без аналогов и замен',
      only_description_basket: 'Без аналогов и замен',
      only_description_label: 'Только',
      // Balance page
      cannot_export_invoice: 'Ошибка выгрузки документа',
      start_date: 'С',
      end_date: 'ПО',
      show: 'Показать',
      topup_online: 'Пополнить картой',
      bank_transfer: 'Банковский перевод',
      document: 'Документ',
      debit: 'Дебет',
      credit: 'Кредит',

      // Basket page
      cannot_select_item: 'Ошибка при выборе позиций в заказ',
      cannot_change_quantity: 'Ошибка изменения количества',
      cannot_apply_changes: 'Ошибка подтверждения изменений',
      cannot_order_selected: 'Ошибка размещения заказа',
      cannot_delete_selected: 'Ошибка при удалении',
      cannot_change_ref: 'Ошибка изменения Reference',
      order: 'Заказ',
      remove: 'Убрать',
      order_accepted:
        'Ваш заказ № {{orderId}} в сумме {{currency}}{{amount}} принят.',
      order_not_accepted:
        'Ваш заказ не принят, код ошибки (code = {{orderId}})',
      activate_account: 'Свяжитесь с нами для активации аккаунта',
      insufficient_funds: 'Недостаточно средств на балансе',
      no_items: 'Не выбраны позиции в заказ',
      incorrect_items: 'Есть некорректные позиции в корзине',
      duplication: 'Дублирование',
      basket_log_in: 'Для размещения заказов авторизуйтесь',
      basket_complete_registration:
        'Для размещения заказов внесите депозит USD500, чтобы завершить регистрацию',

      // Orders page
      part_number: 'Номер детали',
      order_number: 'Номер заказа',
      reference: 'Референс',
      all: 'Все',

      // Password reset page
      submit: 'Сменить',
      new_password: 'НОВЫЙ ПАРОЛЬ',

      // Shipments page
      cannot_update_shipment_date: 'Ошибка изменения даты отгрузки',

      // Balance topup dialog
      amount_with_currency: 'СУММА ({{currency}})',
      charge: 'КОМИССИЯ: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'ПОПОЛНИТЬ',

      // Balance total table
      balance_with_currency: 'Баланс ({{currency}})',
      in_orders: 'В работе',
      in_cart: 'В корзине',
      pay_for_cart: 'Оплатить до корзины',
      ready_to_ship: 'К отгрузке',
      pay_to_ship: 'Оплатить к отгрузке',

      // Basket table
      brand: 'Марка',
      price_with_currency: 'Цена ({{currency}})',
      quantity: 'Количество',
      total_with_currency: 'Сумма ({{currency}})',
      weight_kg: 'Вес (кг)',
      booking: 'Букинг',
      delivery: 'Доставка',
      description: 'Описание',

      // Basket total table
      to_order: 'К заказу',
      available_for_order: 'Доступно к заказу',
      pay_for_order: 'Оплатить к заказу',
      items: 'Позиций',

      // Captcha
      captcha_field_placeholder: 'ВВЕДИТЕ СИМВОЛЫ НА КАРТИНКЕ',

      //Catalog page
      offers_log_in: 'Для скачивания предложений авторизуйтесь',
      offers_complete_registration:
        'Для скачивания предложений внесите депозит USD500, чтобы завершить регистрацию',

      // Catalog table
      offer: 'Предложение',
      download: 'Скачать',
      lines: 'Строк',
      updated: 'Обновлено',

      //Email verified page
      email_verified: 'Почтовый адрес подтвержден',

      // Log In dialog
      email: 'email',
      password: 'пароль',
      forgot_password: 'Забыли пароль?',
      reset_link_sent: 'Ссылка для сброса пароля отправлена вам на почту',

      // Orders table
      date: 'Дата',
      order_price: 'Цена заказа',
      sale_price: 'Цена продажи',
      ordered: 'Заказано',
      purchased: 'Закуплено',
      shipped: 'Отгружено',
      refused: 'Отказано',
      state_date: 'Дата состояния',

      // Search input
      search_input_placeholder: 'Поиск...',

      // Search table
      substituted: 'Замена',
      days: 'Срок',
      available: 'Наличие',
      volume_kg: 'Объем (кг)',
      comment: 'Комментарий',
      n_a: 'НЕТ',
      pack: 'Упаковка',

      // Shipment date dialog
      enabled: 'Активно',

      // Shipment box table
      row_id: 'Row ID',

      // Shipment table
      place: 'Грузоместо',
      length_m: 'Длина (м)',
      height_m: 'Высота (м)',
      width_m: 'Ширина (м)',
      value_with_currency: 'Сумма ({{currency}})',

      // Shipment total table
      places: 'Грузомест',
      volume_m3: 'Объем (㎥)',
      schedule: 'Дата отгрузки',
      choose_date: 'Выберите дату',

      // Sign up dialog
      company_name: 'Наименование компании',
      contact_phone: 'Контактный телефон',
      confirm_password: 'Подтвердите пароль',
      error_password_not_match: 'Пароли не совпадают',

      //Manuals page
      tab_register: 'Зарегистрироваться',
      tab_quotations: 'Проценить',
      tab_offers: 'Скачать прайсы',
      tab_api: 'Подключиться к API',

      //TopUp Dialog
      error_amount: 'Неверная сумма',
      error_something: 'Что-то пошло не так, свяжитесь с нами',

      quote_template_intro: 'Процесс довольно прост:',
      quote_template_step1:
        'Заполните и сохраните Excel файл в заданном формате с интересующими вас номерами деталей',
      quote_template_step2:
        'Вы можете скачать шаблон кнопкой TEMPLATE или использовать существующий',
      quote_template_step2_1:
        'Обязательно соответствие названий колонок шаблону',
      quote_template_step2_2: 'Шаблон содержит следующие колонки:',
      quote_template_PARTNUMBER: 'PARTNUMBER - Номер детали (Обязательно)',
      quote_template_BRAND:
        'BRAND - Опционально, но обязательно, если вы не хотите альтернативных марок',
      quote_template_QUANTITY: 'QUANTITY - Желаемое количество (Опционально)',
      quote_template_PRICE:
        'PRICE - Установите целевую цену (Опционально). Если недоступна, будет показана самая низкая доступная цена с уведомлением',
      quote_template_REFERENCE:
        'REFERENCE - Ваша личная ссылка, будет напечатана на наклейке',
      quote_template_BOOKING:
        'BOOKING - Чтобы выбрать источник цены и доставки (NIP1, AMZB, VF07, ...)',
      quote_template_ONLY:
        'ONLY - Введите "YES", если вы хотите только этой конкретной марки',
      quote_template_MAX_DAYS:
        'MAX DAYS - Выберите лимит времени доставки в поле MAX DAYS',
      quote_template_step4:
        'Нажмите кнопку UPLOAD и выберите подготовленный файл Excel',
      quote_template_step5:
        'Заполните поле QUANTITY для позиций, которые вы хотите, и нажмите TO CART, чтобы перейти к заказу, или нажмите EXPORT, чтобы загрузить результаты оценки',
    },
  },
  pl: {
    translation: {
      //NAVIGATION
      home: 'Strona główna',
      about: 'O nas',
      product_list: 'Lista produktów',
      gallery: 'Galeria',
      contact: 'Kontakt',

      search: 'Szukaj',
      cart: 'Koszyk',
      orders: 'Zamówienia',
      dispatch: 'Wysyłka',
      balance: 'Saldo',
      offers: 'Oferty',

      sign_up: 'Rejestracja',
      log_in: 'Logowanie',
      log_out: 'Wyloguj',
      profile: 'Profil',
      //HOME PAGE
      shop_now: 'Sprawdź katalog',
      news: 'Aktualności i artykuły',
      oem_brands: 'Marki oryginalne',
      aftermarket_brands: 'Marki zamienników',
      view_all: 'Wszystkie aktualności',

      //footer
      my_account: 'Moje konto',
      search_product: 'Wyszukaj produkt',
      shopping_cart: 'Koszyk',
      resources: 'Zasoby',
      faq: 'FAQ',

      //about page
      companyProfileHeading: 'Profil firmy',
      para1:
        ' jest jednym z kluczowych graczy w branży sprzedaży części samochodowych w Dubaju, który całkowicie zmienił marketing uniwersalny i utworzył centrum dla biznesu reeksportowego.',
      para2:
        ' została założona w 1999 roku. Firma w pełni rozumie globalne zapotrzebowanie na części samochodowe w oparciu o wiedzę o zmianach w trendach rynku zagranicznego. Firma rozszerzyła swoją działalność z partnerami w Malezji, Ukrainie (Odessa, Kijów), Bułgarii, Polsce, Niemczech i kilku innych krajach europejskich, na Bliskim Wschodzie i w Zatoce.',
      para3:
        ' podziela wspólną ideę biznesową i stale poprawia asortyment produktów i usług. Utrzymujemy aktywną strategię planowania reeksportu, która zapewnia globalne pojawiające się możliwości. Nasza firma zaspokaja potrzeby wszystkich japońskich, koreańskich, europejskich samochodów oraz części samochodowych. Nasze obszerne portfolio produktów obejmuje zmienny zakres i jakość, w tym oryginalne i zamienne części do',
      para4:
        ' dąży do bycia ważnym organizatorem w handlu reeksportowym części samochodowych, z systematyczną poprawą, sprawną obsługą i niezawodnością. Firma zdobyła światową reputację organizacji i wszechstronne wsparcie gigantów handlowych. Zapewnia to najlepszą obsługę, aby zaspokoić wszystkie Twoje potrzeby w zakresie części samochodowych. Nasz personel i kierownictwo podzielają wspólną ideę, że dostawcy i klienci Nippon nie tylko nas wspierają, ale są podstawą naszego biznesu.',
      para5:
        ' za ich cenne wsparcie przez te lata i zawsze oferujemy nasze najlepsze usługi.',
      para6: 'Dziękujemy i witamy wszystkich naszych klientów i dostawców',
      whyChooseUsHeading: 'Dlaczego warto nas wybrać',
      qualityPolicyHeading: 'Polityka jakości',
      qualityPolicyText:
        'Fundamentalnym celem NIPPON MOTORS FZCO jest dostarczanie wysokiej jakości produktów i obsługi klienta na całym świecie. Dlatego firma zawsze dąży do osiągnięcia poziomu zadowolenia klientów i wizerunku jakości, który odzwierciedli nas jako lidera na naszych rynkach. Aby osiągnąć nasze cele - zaspokojenie potrzeb klientów - konieczne jest, aby zaangażowanie w jakość produktu i jakość obsługi rozprzestrzeniło się na całą organizację. Zatem filozofia firmy polega na tym, że klient określa tę jakość pod względem specyfikacji produktu, kosztów i wymagań dotyczących dostawy.',
      ourMissionHeading: 'Nasza misja',
      ourMissionText:
        'Stać się liderem rynku w regionie z globalnymi interesami, znanym z wysokiej jakości dostarczania produktów i usług, w ekonomiczny i przyjazny sposób, przekraczający oczekiwania klienta, dzięki wysiłkom każdego.',
      ourSpecialtyHeading: 'Nasza specjalizacja',
      ourSpecialtyText1:
        'Naszą specjalizacją jest dostarczanie naszym klientom pełnego asortymentu części zamiennych do silnika i zawieszenia do japońskich i koreańskich samochodów z silnikiem diesla i benzynowym. Pozwala nam to łączyć originalne części i części zamienne wysokiej jakości pod jednym dachem.',
      ourSpecialtyText2:
        'Wierzymy, że ta kombinacja daje nam przewagę nad naszymi konkurentami. Oferujemy ten pakiet usług, z którego korzystają nasi klienci, wiedząc, że wszystkie ich wymagania są koordynowane przez jednego dealera.',
      item1: 'Paski rozrządu, Zestawy rozrządu, Łańcuchy rozrządu',
      item2: 'Paski klinowe, Paski wentylatorów',
      item3: 'Tłoki, Tuleje cylindrowe',
      item4: 'Pierścienie tłokowe',
      item5: 'Uszczelki zaworów, Uszczelki olejowe',
      item6: 'Części zawieszenia',
      item7: 'Osłony przegubów napędowych',
      item8: 'Uszczelki pokrywy zaworów, Misy olejowe',
      item9: 'Osłony amortyzatorów',
      item10: 'Klocki hamulcowe, Szczęki hamulcowe',
      item11: 'Filtry powietrza, Filtry oleju, Filtry paliwa',
      item12: 'Łożyska wału korbowego, Łożyska korbowodów',
      item13: 'Zawory silnika, Prowadnice zaworów',
      item14: 'Łożyska silnika, Łożyska kół',

      //contact page
      get_in_touch: 'Skontaktuj się z nami',
      contact_us: 'Kontakt',
      email_address: 'Adres email',
      office_location: 'Lokalizacja biura',
      enquire_now: 'Zapytaj teraz',
      firstName: 'Imię*',
      lastName: 'Nazwisko*',
      phoneNumber: 'Telefon*',
      message: 'Wiadomość*',
      message_placeholder: 'Napisz swoją wiadomość',
      submit_form: 'Wyślij',
      error: 'Błąd',
      success: 'Wysłano',
      //user dialog
      user_name: 'NAZWA',
      user_country: 'KRAJ',
      user_zip: 'KOD POCZTOWY',
      user_state: 'WOJEWÓDZTWO/REGION',
      user_city: 'MIASTO',
      user_street: 'ULICA',
      user_building: 'BUDYNEK',
      user_flat: 'MIESZKANIE/BIURO',
      user_phone: 'TELEFON',
      user_email: 'EMAIL',
      user_tax: 'NUMER PODATKOWY',
      user_currency: 'WALUTA',
      user_contact_person: 'OSOBA KONTAKTOWA',
      user_contact_email: 'EMAIL',
      user_contact_phone: 'TELEFON KONTAKTOWY',
      save: 'ZAPISZ',

      //verify email
      verify_email_processing: 'Przetwarzanie...',
      verify_email_heading: 'Weryfikacja adresu email',
      verify_email_text: 'Wysłaliśmy wiadomość na adres',
      verify_email_text2:
        'Sprawdź swoją skrzynkę odbiorczą i kliknij link w wiadomości',
      close_text: 'Zamknij',
      // Search page
      by_part_number: 'Szukaj po numerze',
      by_excel_file: 'Wycena przez plik',
      to_cart: 'Do koszyka',
      export: 'Export',
      upload: 'Załaduj',
      max_days: 'Nie później niż',
      template: 'Szablon',
      demo_prices_log_in: 'Widzisz DEMO ceny. Zaloguj się',
      demo_prices_complete_registration:
        'Widzisz DEMO ceny. Wpłać depozyt 500 USD, aby zakończyć rejestrację',
      for_order: 'ZAMÓWIENIE',
      error_quotation: 'Błąd wykonania wyceny',
      quotation_differs:
        'W przypadku części pozycji różni się cena, dostępność lub czas dostawy',
      where_to_dispatch: 'Dostawa',
      only_description_tooltip: 'Wyszukiwanie bez zamienników i alternatyw',
      only_description_basket: 'Bez zamienników i alternatyw',
      only_description_label: 'Tylko',
      // Balance page
      cannot_export_invoice: 'Błąd eksportu dokumentu',
      start_date: 'OD',
      end_date: 'DO',
      show: 'Pokaż',
      topup_online: 'Doładuj kartą',
      bank_transfer: 'Przelew bankowy',
      document: 'Dokument',
      debit: 'Debet',
      credit: 'Kredyt',

      // Basket page
      cannot_select_item: 'Błąd podczas wybierania pozycji do zamówienia',
      cannot_change_quantity: 'Błąd zmiany ilości',
      cannot_apply_changes: 'Błąd potwierdzenia zmian',
      cannot_order_selected: 'Błąd składania zamówienia',
      cannot_delete_selected: 'Błąd podczas usuwania',
      cannot_change_ref: 'Błąd zmiany Referencji',
      order: 'Zamówienie',
      remove: 'Usuń',
      order_accepted:
        'Twoje zamówienie nr {{orderId}} na kwotę {{currency}}{{amount}} zostało przyjęte.',
      order_not_accepted:
        'Twoje zamówienie nie zostało przyjęte, kod błędu (code = {{orderId}})',
      activate_account: 'Skontaktuj się z nami, aby aktywować konto',
      insufficient_funds: 'Niewystarczające środki na koncie',
      no_items: 'Nie wybrano pozycji do zamówienia',
      incorrect_items: 'W koszyku są nieprawidłowe pozycje',
      duplication: 'Duplikacja',
      basket_log_in: 'Aby składać zamówienia, zaloguj się',
      basket_complete_registration:
        'Aby składać zamówienia, wpłać depozyt 500 USD, aby zakończyć rejestrację',

      // Orders page
      part_number: 'Numer części',
      order_number: 'Numer zamówienia',
      reference: 'Referencja',
      all: 'Wszystkie',

      // Password reset page
      submit: 'Zmień',
      new_password: 'NOWE HASŁO',

      // Shipments page
      cannot_update_shipment_date: 'Błąd zmiany daty wysyłki',

      // Balance topup dialog
      amount_with_currency: 'KWOTA ({{currency}})',
      charge: 'PROWIZJA: {{percent}}% + {{fixed}} {{currency}}',
      topup: 'DOŁADUJ',

      // Balance total table
      balance_with_currency: 'Saldo ({{currency}})',
      in_orders: 'W realizacji',
      in_cart: 'W koszyku',
      pay_for_cart: 'Zapłać do koszyka',
      ready_to_ship: 'Gotowe do wysyłki',
      pay_to_ship: 'Zapłać do wysyłki',

      // Basket table
      brand: 'Marka',
      price_with_currency: 'Cena ({{currency}})',
      quantity: 'Ilość',
      total_with_currency: 'Suma ({{currency}})',
      weight_kg: 'Waga (kg)',
      booking: 'Rezerwacja',
      delivery: 'Dostawa',
      description: 'Opis',

      // Basket total table
      to_order: 'Do zamówienia',
      available_for_order: 'Dostępne do zamówienia',
      pay_for_order: 'Zapłać za zamówienie',
      items: 'Pozycji',

      // Captcha
      captcha_field_placeholder: 'WPROWADŹ ZNAKI Z OBRAZKA',

      //Catalog page
      offers_log_in: 'Aby pobrać oferty, zaloguj się',
      offers_complete_registration:
        'Aby pobrać oferty, wpłać depozyt 500 USD, aby zakończyć rejestrację',

      // Catalog table
      offer: 'Oferta',
      download: 'Pobierz',
      lines: 'Wierszy',
      updated: 'Zaktualizowano',

      //Email verified page
      email_verified: 'Adres email zweryfikowany',

      // Log In dialog
      email: 'email',
      password: 'hasło',
      forgot_password: 'Zapomniałeś hasła?',
      reset_link_sent: 'Link do resetowania hasła został wysłany na twój email',

      // Orders table
      date: 'Data',
      order_price: 'Cena zamówienia',
      sale_price: 'Cena sprzedaży',
      ordered: 'Zamówiono',
      purchased: 'Zakupiono',
      shipped: 'Wysłano',
      refused: 'Odrzucono',
      state_date: 'Data stanu',

      // Search input
      search_input_placeholder: 'Szukaj...',

      // Search table
      substituted: 'Zamiennik',
      days: 'Dni',
      available: 'Dostępność',
      volume_kg: 'Objętość (kg)',
      comment: 'Komentarz',
      n_a: 'BRAK',
      pack: 'Opakowanie',

      // Shipment date dialog
      enabled: 'Aktywne',

      // Shipment box table
      row_id: 'ID wiersza',

      // Shipment table
      place: 'Miejsce',
      length_m: 'Długość (m)',
      height_m: 'Wysokość (m)',
      width_m: 'Szerokość (m)',
      value_with_currency: 'Wartość ({{currency}})',

      // Shipment total table
      places: 'Miejsca',
      volume_m3: 'Objętość (㎥)',
      schedule: 'Data wysyłki',
      choose_date: 'Wybierz datę',

      // Sign up dialog
      company_name: 'Nazwa firmy',
      contact_phone: 'Telefon kontaktowy',
      confirm_password: 'Potwierdź hasło',
      error_password_not_match: 'Hasła nie są zgodne',

      //Manuals page
      tab_register: 'Zarejestruj się',
      tab_quotations: 'Wycena',
      tab_offers: 'Pobierz cenniki',
      tab_api: 'Połącz z API',

      //TopUp Dialog
      error_amount: 'Nieprawidłowa kwota',
      error_something: 'Coś poszło nie tak, skontaktuj się z nami',

      quote_template_intro: 'Proces jest dość prosty:',
      quote_template_step1:
        'Wypełnij i zapisz plik Excel w określonym formacie z numerami części, które Cię interesują',
      quote_template_step2:
        'Możesz pobrać szablon przyciskiem TEMPLATE lub użyć istniejącego',
      quote_template_step2_1:
        'Ważne jest, aby nazwy kolumn odpowiadały szablonowi',
      quote_template_step2_2: 'Szablon zawiera następujące kolumny:',
      quote_template_PARTNUMBER: 'PARTNUMBER - Numer części (Wymagane)',
      quote_template_BRAND:
        'BRAND - Opcjonalne, ale wymagane, jeśli nie chcesz alternatywnych marek',
      quote_template_QUANTITY: 'QUANTITY - Pożądana ilość (Opcjonalnie)',
      quote_template_PRICE:
        'PRICE - Ustaw cenę docelową (Opcjonalnie). Jeśli niedostępna, zostanie pokazana najniższa dostępna cena z powiadomieniem',
      quote_template_REFERENCE:
        'REFERENCE - Twoja osobista referencja, zostanie wydrukowana na naklejce',
      quote_template_BOOKING:
        'BOOKING - Aby wybrać źródło ceny i dostawy (NIP1, AMZB, VF07, ...)',
      quote_template_ONLY:
        'ONLY - Wpisz "YES", jeśli chcesz tylko tej konkretnej marki',
      quote_template_MAX_DAYS:
        'MAX DAYS - Wybierz limit czasu dostawy w polu MAX DAYS',
      quote_template_step4:
        'Kliknij przycisk UPLOAD i wybierz przygotowany plik Excel',
      quote_template_step5:
        'Wypełnij pole QUANTITY dla pozycji, które chcesz i kliknij TO CART, aby przejść do zamówienia, lub kliknij EXPORT, aby pobrać wyniki wyceny',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
