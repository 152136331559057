import {
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function HelpTooltipContent() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        fontSize: '1rem',
        padding: '4px',
      }}
    >
      <List dense>
        <ListItem>
          <ListItemText primary={t('quote_template_step1')} />
        </ListItem>
        <ListItem>
          <ListItemText primary={t('quote_template_step2')} />
        </ListItem>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          {t('quote_template_step2_1')}
        </Typography>
        <div
          style={{
            padding: '8px',
            border: '1px solidrgb(121, 121, 121)',
            borderRadius: '4px',
            backgroundColor: 'rgb(245, 245, 245)',
          }}
        >
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <ListItemText
                    primary="PartNumber"
                    primaryTypographyProps={{ fontWeight: '500' }}
                  />
                </TableCell>
                <TableCell>
                  <ListItemText primary={t('quote_template_PARTNUMBER')} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <ListItemText primary="Brand" />
                </TableCell>
                <TableCell>
                  <ListItemText primary={t('quote_template_BRAND')} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <ListItemText primary="Quantity" />
                </TableCell>
                <TableCell>
                  <ListItemText primary={t('quote_template_QUANTITY')} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <ListItemText primary="Price" />
                </TableCell>
                <TableCell>
                  <ListItemText primary={t('quote_template_PRICE')} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <ListItemText primary="Reference" />
                </TableCell>
                <TableCell>
                  <ListItemText primary={t('quote_template_REFERENCE')} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <ListItemText primary="Booking" />
                </TableCell>
                <TableCell>
                  <ListItemText primary={t('quote_template_BOOKING')} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <ListItemText primary="Only" />
                </TableCell>
                <TableCell>
                  <ListItemText primary={t('quote_template_ONLY')} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <ListItemText primary="MaxDays" />
                </TableCell>
                <TableCell>
                  <ListItemText primary={t('quote_template_MAX_DAYS')} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <ListItem>
          <ListItemText primary={t('quote_template_step4')} />
        </ListItem>
        <ListItem>
          <ListItemText primary={t('quote_template_step5')} />
        </ListItem>
      </List>
    </div>
  );
}
