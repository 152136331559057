export const translateText = async (text) => {
  try {
    const targetLanguage = localStorage.getItem('selectedLanguage') || 'en';

    if (targetLanguage === 'en') {
      return text;
    }

    const formattedTargetLanguage = targetLanguage.split('-')[0];

    const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;

    if (!apiKey) {
      console.error(
        'Google Translate API key not found in environment variables'
      );
      return text;
    }

    const endpoint = 'https://translation.googleapis.com/language/translate/v2';

    const params = new URLSearchParams({
      q: text,
      target: formattedTargetLanguage,
      source: 'en',
      format: 'text',
    });

    const response = await fetch(`${endpoint}?${params.toString()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-goog-api-key': apiKey,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Translation API error: ${
          errorData.error?.message || response.statusText
        }`
      );
    }

    const data = await response.json();

    return data.data.translations[0].translatedText;
  } catch (error) {
    console.error('Translation failed:', error);
    return text;
  }
};
