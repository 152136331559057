import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Logo from '../assets/logo.jpg';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PageTabs from './PageTabs';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import LanguageIcon from '@material-ui/icons/Language';

const PageHeader = ({
  classes,
  companyName,
  companyAddress,
  customerCode,
  isLoggedIn,
  width,
  onLogin,
  onLogout,
  onSignUp,
  onUserData,
}) => {
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [langMenuAnchor, setLangMenuAnchor] = React.useState(null);

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openLangMenu = (event) => {
    setLangMenuAnchor(event.currentTarget);
  };

  const closeLangMenu = () => {
    setLangMenuAnchor(null);
  };

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
    closeLangMenu();
  };
  const isMobile = isWidthDown('xs', width);
  const containMenu = isWidthDown('md', width);
  const hideSecondRow = isWidthDown('md', width);
  const buttonVariant = isMobile ? 'text' : 'contained';
  return (
    <>
      <div className={classes.header}>
        <Toolbar className={classes.toolbar}>
          {containMenu && (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
                <div
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <Link
                    to="/home"
                    style={{ padding: '16px', textAlign: 'center' }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/logo/nav-logo.jpg`}
                      alt="Logo"
                      style={{ maxWidth: '300px' }}
                    />
                  </Link>
                  <List>
                    <ListItem button component={Link} to="/">
                      {t('home')}
                    </ListItem>
                    <ListItem button component={Link} to="/about">
                      {t('about')}
                    </ListItem>
                    <ListItem button component={Link} to="/news">
                      {t('news').split(' ')[0]}
                    </ListItem>
                    <ListItem button component={Link} to="/search">
                      {t('product_list')}
                    </ListItem>
                    <ListItem button component={Link} to="/gallery">
                      {t('gallery')}
                    </ListItem>
                    <ListItem button component={Link} to="/contact">
                      {t('contact')}
                    </ListItem>
                  </List>
                </div>
              </Drawer>
            </>
          )}

          <div className={classes.logo}>
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/logo/nav-logo.jpg`}
              />
            </Link>
          </div>
          <PageTabs
            isLoggedIn={isLoggedIn}
            hideSecondRow
            hideFirstRow={containMenu}
          />

          <div className={classes.headerButtons}>
            <IconButton
              className={classes.iconButtonLabel}
              onClick={handleClick}
            >
              <PersonIcon style={{ color: '#fff' }} />
              <div className={classes.loginText}>
                {isLoggedIn ? customerCode : undefined}
              </div>
            </IconButton>
            <Menu
              classes={{
                paper: classes.menuPaper,
              }}
              anchorEl={anchorEl}
              elevation={5}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {!isLoggedIn ? (
                <div key="profileMenu">
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onSignUp();
                    }}
                  >
                    {' '}
                    {t('sign_up')}{' '}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onLogin();
                    }}
                  >
                    {' '}
                    {t('log_in')}{' '}
                  </MenuItem>
                </div>
              ) : (
                <div key="profileMenu">
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onUserData();
                    }}
                  >
                    {' '}
                    {t('profile')}{' '}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onLogout();
                    }}
                  >
                    {' '}
                    {t('log_out')}{' '}
                  </MenuItem>
                </div>
              )}
            </Menu>

            <IconButton
              color="inherit"
              aria-label="language"
              onClick={openLangMenu}
              style={{ color: '#fff' }}
            >
              <LanguageIcon style={{ color: '#fff', marginRight: '2px' }} />{' '}
              {i18n.language}
            </IconButton>

            <Menu
              anchorEl={langMenuAnchor}
              keepMounted
              classes={{
                paper: classes.menuPaper,
              }}
              open={Boolean(langMenuAnchor)}
              onClose={closeLangMenu}
            >
              <MenuItem onClick={() => switchLanguage('en')}>English</MenuItem>
              <MenuItem onClick={() => switchLanguage('ru')}>Russian</MenuItem>
              <MenuItem onClick={() => switchLanguage('pl')}>Polish</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </div>
      <div
        style={{
          backgroundColor: '#194681',
          width: '100%',
          maxWidth: '1264px',
        }}
      >
        <PageTabs
          isLoggedIn={isLoggedIn}
          hideFirstRow
          //hideSecondRow={!hideSecondRow}
        />
      </div>
    </>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.pageHeader;
  return {
    header: {
      backgroundColor: colors.header,
      width: '100%',
      minHeight: '96px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        minHeight: '72px',
      },
    },
    headerButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
    },
    loginButton: {
      // border: `1px solid ${colors.loginButton.fontColor}`,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing.unit,
      },
      // backgroundColor: colors.loginButton.default,
      // color: colors.loginButton.fontColor,
      // "&:hover": {
      //   backgroundColor: colors.loginButton.hovered
      // }
    },
    signUpButton: {
      marginLeft: theme.spacing.unit * 2,
      // backgroundColor: colors.signUpButton.default,
      // color: colors.signUpButton.fontColor,
      // "&:hover": {
      //   backgroundColor: colors.signUpButton.hovered
      // }
    },
    menuPaper: {
      transform: 'translateY(3rem) !important',
      borderRadius: '5px',
    },
    toolbar: {
      flex: '1 0 0px',
      justifyContent: 'space-between',
    },
    name: {
      color: theme.palette.custom.logo,
      marginLeft: theme.spacing.unit,
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      minWidth: '150px',
      '& > img': {
        height: '64px',
      },
    },
    address: {
      color: theme.palette.custom.logo,
      fontWeight: 'normal',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    business: {
      color: theme.palette.custom.logo,
      marginLeft: theme.spacing.unit,
      fontWeight: 'normal',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    loginText: {
      color: '#fff',
      marginLeft: theme.spacing.unit,
    },
    iconButtonLabel: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
};

export default withWidth()(withStyles(styles)(PageHeader));
